import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


function SEO({ description, lang, meta, title, keywords, image,single_image, image_format, image_height, img_width, facebook_url, twitter_page_username, twitter_image_alt_tag, twitter_estimated_reading_lable, twitter_estimated_reading_time, page_publish_date, page_last_modified_time, currentPageUrlPath, sitelinks_schema, breadcrumb_schema, logo_schema, organization_schema, aggregate_rating_schema, local_business_schema, site_navigation_schema, speakable_specification_schema, hreflang1, hreflang2, hreflang3, hreflang4 }) {
  
  const localTime = new Date(page_publish_date)
  const modifiedTime = new Date(page_last_modified_time)

  const date = new Date(localTime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const offset = '+00:00';
  const isoDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}${offset}`;
  
  const modifieddate = new Date(modifiedTime);
  const modifiedyear = modifieddate.getFullYear();
  const modifiedmonth = modifieddate.getMonth() + 1;
  const modifiedday = modifieddate.getDate();
  const modifiedhours = modifieddate.getHours();
  const modifiedminutes = modifieddate.getMinutes();
  const modifiedseconds = modifieddate.getSeconds();
  const modifiedoffset = '+00:00';
  const modifiedisoDate = `${modifiedyear}-${modifiedmonth.toString().padStart(2, '0')}-${modifiedday.toString().padStart(2, '0')}T${modifiedhours.toString().padStart(2, '0')}:${modifiedminutes.toString().padStart(2, '0')}:${modifiedseconds.toString().padStart(2, '0')}${modifiedoffset}`;
  
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const metaTitle = title || ''
  const metaDescription = description || ''
  const metaKeywords = keywords || ''
  const metaImage = image ? `${site.siteMetadata.siteUrl}${image}` : ''
  const singlemetaImage = single_image ? single_image : ''
  const canonical = site.siteMetadata.siteUrl
  const imageformat = image_format ? image_format : ''
  const imageheight = image_height ? image_height : ''
  const imagewidth = img_width ? img_width : ''
  const facebookUrl = facebook_url ? facebook_url : ''
  const twitterPageUsername = twitter_page_username ? twitter_page_username : ''
  const twitterImageAltTag = twitter_image_alt_tag ? twitter_image_alt_tag : ''
  const twitterEstimatedReadingLable = twitter_estimated_reading_lable ? twitter_estimated_reading_lable : ''
  const twitterEstimatedReadingTime = twitter_estimated_reading_time ? twitter_estimated_reading_time : ''
  const pagePublicDate = isoDate ? isoDate === "NaN-NaN-NaNTNaN:NaN:NaN+00:00" ? "" : isoDate : ''
  const pageLastModifiedTime = modifiedisoDate ? modifiedisoDate === "NaN-NaN-NaNTNaN:NaN:NaN+00:00" ? '' : modifiedisoDate : ''
  const currentUrl = currentPageUrlPath ? currentPageUrlPath : ''
  const sitelinkSchema = sitelinks_schema ? sitelinks_schema : ''
  const breadcrumbSchema = breadcrumb_schema ? breadcrumb_schema : ''
  const logoSchema = logo_schema ? logo_schema : ''
  const organizationSchema = organization_schema ? organization_schema : ''
  const aggregateRatingSchema = aggregate_rating_schema ? aggregate_rating_schema : ''
  const localBusinessSchema = local_business_schema ? local_business_schema : ''
  const siteNavigationSchema = site_navigation_schema ? site_navigation_schema : ''
  const speakableSpecificationSchema = speakable_specification_schema ? speakable_specification_schema : ''
  const hreflang_1 = hreflang1 || ''
  const hreflang_2 = hreflang2 || ''
  const hreflang_3 = hreflang3 || ''
  const hreflang_4 = hreflang4 || ''

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={metaTitle}
        link={
          canonical
            ? [
              {
                rel: "canonical",
                href: `${canonical}${currentUrl}`,
              },
            ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: "keywords",
            content: metaKeywords,
          },
          {
            name: "image",
            content: singlemetaImage,
          },
          {
            property: `og:locale`,
            content: `en_US`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:site_name`,
            content: `DecodeUp (P) Ltd.`,
          },
          {
            property: `og:url`,
            content: `${canonical}${currentUrl}`,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: "og:image",
            content: metaImage,
          },
          {
            property: `og:image:type`,
            content: imageformat,
          },
          {
            property: `og:image:height`,
            content: imageheight,
          },
          {
            property: `og:image:width`,
            content: imagewidth,
          },
          {
            property: `article:publisher`,
            content: facebookUrl,
          },
          {
            name: `twitter:site`,
            content: twitterPageUsername,
          },

          {
            name: `twitter:creator`,
            content: twitterPageUsername,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: `twitter:url`,
            content: `${canonical}${currentUrl}`,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },

          {
            name: `twitter:description`,
            content: metaDescription,
          },

          {
            name: `twitter:image`,
            content: metaImage,
          },
          {
            name: `twitter:image:alt`,
            content: twitterImageAltTag,
          },
          {
            name: `twitter:label1`,
            content: twitterEstimatedReadingLable,
          },
          {
            name: `twitter:data1`,
            content: twitterEstimatedReadingTime,
          },
          {
            name: `article:published_time`,
            content: pagePublicDate,
          }, {
            name: `article:modified_time`,
            content: pageLastModifiedTime,
          },
        ].concat(meta)}
      />
      <Helmet>
        {sitelinkSchema && <script data-react-helmet="true" type="application/ld+json">{sitelinkSchema}</script>}
        {breadcrumbSchema && <script data-react-helmet="true" type="application/ld+json">{breadcrumbSchema}</script>}
        {logoSchema && <script data-react-helmet="true" type="application/ld+json">{logoSchema}</script>}
        {organizationSchema && <script data-react-helmet="true" type="application/ld+json">{organizationSchema}</script>}
        {aggregateRatingSchema && <script data-react-helmet="true" type="application/ld+json">{aggregateRatingSchema}</script>}
        {localBusinessSchema && <script data-react-helmet="true" type="application/ld+json">{localBusinessSchema}</script>}
        {siteNavigationSchema && <script data-react-helmet="true" type="application/ld+json">{siteNavigationSchema}</script>}
        {speakableSpecificationSchema && <script data-react-helmet="true" type="application/ld+json">{speakableSpecificationSchema}</script>}

        {hreflang_1 && <link data-react-helmet="true" rel="alternate" href={`${canonical}${currentUrl}`} hreflang={hreflang_1} />}
        {hreflang_2 && <link data-react-helmet="true" rel="alternate" href={`${canonical}${currentUrl}`} hreflang={hreflang_2} />}
        {hreflang_3 && <link data-react-helmet="true" rel="alternate" href={`${canonical}${currentUrl}`} hreflang={hreflang_3} />}
        {hreflang_4 && <link data-react-helmet="true" rel="alternate" href={`${canonical}${currentUrl}`} hreflang={hreflang_4} />}
      </Helmet>

    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
